import React from 'react';
import { useState } from 'react';

function ContactModule({mainDivClassName})
{
    const [data, setData] = useState({service: "send", name: "", mail: "", message: ""});

    const handleChange = (e) =>{
        const name = e.target.name;
        const value = e.target.value;
        setData({...data, [name]: value});

        if(data.name == "" || data.mail == "" || data.message == "")
        {
            document.getElementById('submit').setAttribute('disabled', 'disabled');
            document.getElementById('submit').style.borderColor = '#BBBBBB';
            document.getElementById('submit').style.backgroundColor = 'rgba(220,220,220,.1)';
            document.getElementById('submit').style.color = '#BBBBBB';
        }
        else
        {
            document.getElementById('submit').removeAttribute('disabled');
            document.getElementById('submit').style.borderColor = '#1D58CD';
            document.getElementById('submit').style.backgroundColor = 'EDECF6';
            document.getElementById('submit').style.color = '#1D58CD';
        }
    }

    const handleBlur = (e) =>{
        if(data.name == "")
        {
            document.getElementById('name').style.borderColor = '#FF2F43';
            document.getElementById('name').style.backgroundColor = 'rgba(255,47,67,.1)';
        }
        else
        {
            document.getElementById('name').style.borderColor = '#1D58CD';
            document.getElementById('name').style.backgroundColor = '#FFFFFF';
        }
        if(data.mail == "")
        {
            document.getElementById('mail').style.borderColor = '#FF2F43';
            document.getElementById('mail').style.backgroundColor = 'rgba(255,47,67,.1)';
        }
        else
        {
            document.getElementById('mail').style.borderColor = '#1D58CD';
            document.getElementById('mail').style.backgroundColor = '#FFFFFF';
        }
        if(data.message == "")
        {
            document.getElementById('message').style.borderColor = '#FF2F43';
            document.getElementById('message').style.backgroundColor = 'rgba(255,47,67,.1)';
        }
        else
        {
            document.getElementById('message').style.borderColor = '#1D58CD';
            document.getElementById('message').style.backgroundColor = '#FFFFFF';
        }

        if(data.name == "" || data.mail == "" || data.message == "")
        {
            document.getElementById('submit').setAttribute('disabled', 'disabled');
            document.getElementById('submit').style.borderColor = '#BBBBBB';
            document.getElementById('submit').style.backgroundColor = 'rgba(220,220,220,.1)';
            document.getElementById('submit').style.color = '#BBBBBB';
        }
        else
        {
            document.getElementById('submit').removeAttribute('disabled');
            document.getElementById('submit').style.borderColor = '#1D58CD';
            document.getElementById('submit').style.backgroundColor = 'EDECF6';
            document.getElementById('submit').style.color = '#1D58CD';
        }
    }

    const handleSubmit = (e) =>{
        const form = document.getElementById('form');
        e.preventDefault();
        fetch(form.action, {
            method: "post",
            body: new FormData(form)
        });
        alert('Su mensaje ha sido enviado con éxito. Nos comunicaremos a su correo en menos de 1 día hábil.');
    }

    return(
        <div>
            <form id='form' className={mainDivClassName} onSubmit={handleSubmit} action='services.php'>
                <input type='hidden' name='service' value='send'/>
                <input type='name' id='name' name='name' value={data.name} className='name' placeholder='Nombre' onChange={handleChange} onBlur={handleBlur}/>
                <input type='email' id='mail' name='mail' value={data.mail} className='email' placeholder='Correo Electrónico' onChange={handleChange} onBlur={handleBlur}/>
                <textarea name='message' id='message' value={data.message} className='message' type="text" placeholder='Mensaje' onChange={handleChange} onBlur={handleBlur}/> <br></br>
                <button type='submit' id='submit' className='submit' disabled>Enviar</button>
            </form>
        </div>
    );
}

export default ContactModule;