import './App.css';
import Carrousel from './Components/Carrousel.jsx';
import ContactModule from './Components/ContactModule.jsx';

function App() {
  return (
    <div className="App">
      <nav>
        <img className="logo-img" src="/imgs/Logo Black.svg" alt="Logo"></img>
        <div className= "text-containers-container">
        </div>
      </nav>
      <Carrousel mainDivClassName="main-carrousel-container"/>
      <div className='intro-container'>
        <p className='prelude'>Nuestra consejería digital te <b>promete</b> soluciones</p>
        <img className='concepts' src='/imgs/_Concepts.svg'></img>
        <img className='concepts-mobile' src='/imgs/Concepts mobile.svg'></img>
      </div>
      <section className="how-we-help-you-container">
        <h3 className="consierge-title">Te asesoramos</h3>
        <article className="consierge-container">
          <img className="consierge-img" src='/imgs/Concierge.jpg' alt=''/>
          <p className="consierge-text">Con nosotros, no tendrás que preocuparte por conseguir a las personas adecuadas para obtener los resultados que esperas. Contamos con expertos en áreas de la <b>informática, programación, marketing y branding</b>, con quienes puedes contar para estar seguro que tu empresa tendrá justo lo que necesita con la eficiencia y profesionalismo que solo nosotros podemos darte.</p>
        </article>
        <h3 className="website-title">Creamos tu sitio web</h3>
        <article className="website-container">
          <p className="website-text">Con más de <b>80 millones</b> de mexicanos conectados al internet, es de esperarse que los sitios web se han convertido en una de las herramientas primordiales para el crecimiento y la supervivencia de las empresas. Nosotros <b>creamos tu sitio web</b> completamente personalizado y ajustado a las necesidades de tu empresa de manera <b>rápida y funcional</b>.</p>
          <img className="website-img"  src='/imgs/Website.jpg' alt=''/>
        </article>
        <h3 className="socials-title">Manejamos tus redes sociales</h3>
        <article className="socials-container">
          <img className="socials-img"  src='/imgs/Socials.jpg' alt=''/>
          <p className="socials-text">A todos nos gusta sentirnos conectados con las empresas que nos apoyan en el día a día y tus clientes no son la excepción. Te ayudamos a <b>crear contenido</b> y <b>publicaciones relevantes</b> para tu empresa y llegar a tu público deseado de la mejor manera a través de las redes sociales más influyentes y con <b>estrategias de marketing</b> refinadas para tus necesidades.</p>
        </article>
      </section>
      <h2 className="contact-title">¡Contáctanos!</h2>
      <center><ContactModule mainDivClassName="contact-container"/></center>
      <footer>
        <img src='/imgs/Logo.svg' alt='logo' className='logo'/>
        <p className='phone-number'>Teléfono: +52 612-212-0852</p>
        <p className='email'>Correo: contacto@consilium.uno</p>
      </footer>
    </div>
  );
}

export default App;
