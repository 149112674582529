import React from 'react';

function Carrousel({mainDivClassName})
{
    return(
        <div className={mainDivClassName}>
            <img src="./imgs/Slide.svg" alt="" className='slide1'/>
            <img src="./imgs/MobileSlide.svg" alt="" className='slide1-mobile'/>
            <i>INEGI. Demografía de los Negocios 1989-2019.</i>
        </div>
    );
}

export default Carrousel;